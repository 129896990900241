import { Message } from 'vue-advanced-chat';
import ChatMessage from '@/chat/chat.message';
import { formatShortDate } from '@/utils/date_time';

export interface UIMessage extends Message {
  avatar?: string;
}

export const uiMessageFromChatMessage = (m: ChatMessage): UIMessage => {
  return {
    _id: m.id,
    content: m.text,
    senderId: m.senderId,
    timestamp: formatShortDate(m.createdDate.getTime()),
    username: m.senderName,
    date: '',
    reactions: {},
    avatar: m.senderProfilePictureURL,
  };
};
