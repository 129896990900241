








































import Vue from 'vue';
import ChatWindow, {
  Message,
  Room,
  RoomUser,
  RoomUsers,
  UserStatus,
} from 'vue-advanced-chat';
import 'vue-advanced-chat/dist/vue-advanced-chat.css';
import RideeUser from '@/dtos/user';
import Scaffold from '@/components/Mobile/Scaffold.vue';
import { mapGetters } from 'vuex';
import { UIMessage, uiMessageFromChatMessage } from '@/chat/chat.ui.message';
import ChatMessage from '@/chat/chat.message';
import { isRunningInTheApp } from '@/utils/platform';

interface UIRoom extends Room {
  avatar?: string;
}

export default Vue.extend({
  components: {
    Scaffold,
    ChatWindow,
  },
  props: {
    clubId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      messageActions: [
        {
          name: 'editMessage',
          title: this.$t('chat.message.edit.title'),
          onlyMe: true,
        },
        {
          name: 'deleteMessage',
          title: this.$t('chat.message.delete.title'),
          onlyMe: true,
        },
      ],
      styles: {
        container: { borderRadius: '4px' },
        general: {
          color: '#0a0a0a',
          colorSpinner: '#333',
          borderStyle: '1px solid #e1e4e8',
        },

        footer: {
          background: '#f8f9fa',
          backgroundReply: 'rgba(0, 0, 0, 0.08)',
        },
        icons: {
          search: '#9ca6af',
        },
      },
      theme: 'light',
      textMessages: {
        ROOMS_EMPTY: this.$t('chat.rooms.empty.note'),
        ROOM_EMPTY: this.$t('chat.room.empty.note'),
        NEW_MESSAGES: this.$t('chat.message.new.note'),
        MESSAGE_DELETED: this.$t('chat.message.deleted.note'),
        MESSAGES_EMPTY: '',
        CONVERSATION_STARTED: this.$t('chat.conversation.started.note'),
        TYPE_MESSAGE: this.$t('chat.message.typing.note'),
        SEARCH: this.$t('chat.search.note'),
        IS_ONLINE: this.$t('chat.user.online.note'),
        LAST_SEEN: this.$t('chat.user.lastseen.note'),
        IS_TYPING: this.$t('chat.user.typing.note'),
      },
    };
  },
  computed: {
    ...mapGetters({
      club: 'user/getUserProfile',
      messages: 'clubchat/getMessages',
    }),
    addBottomPadding(): boolean {
      return !isRunningInTheApp();
    },
    currentUserId(): RideeUser {
      return this.$store.getters['auth/getUser'].data.uid;
    },
    rooms(): UIRoom[] {
      return [
        {
          roomId: this.clubId,
          roomName: this.club.displayName,
          avatar: this.club.profileImageURI,
          unreadCount: 0,
          index: this.clubId,
          lastMessage: undefined,
          users: this.roomUsers,
        },
      ];
    },
    roomUsers(): RoomUsers {
      const users: { [key: string]: RoomUser } = {};
      (this.messages as ChatMessage[]).forEach((m: ChatMessage) => {
        users[m.senderId] = {
          _id: m.senderId,
          username: m.senderName || '',
          status: {
            state: 'online',
            lastChanged: '',
          } as UserStatus,
          avatar: m.senderProfilePictureURL,
        };
      });
      return Object.values(users);
    },
    uiMessages(): UIMessage[] {
      return this.messages.map(uiMessageFromChatMessage);
    },
  },
  async mounted() {
    const dispatch = this.$store.dispatch;
    await dispatch('user/bindUserProfile', this.clubId);
    dispatch('clubchat/bindMessages', {
      clubId: this.clubId,
      clubDisplayName: this.club.displayName,
    });
  },
  beforeDestroy() {
    this.$store.dispatch('clubchat/unbindMessages');
  },
  methods: {
    sendMessage({ content }: { content: string }) {
      this.$store.dispatch('clubchat/sendMessage', content);
    },
    editMessage({
      messageId,
      newContent,
    }: {
      messageId: string;
      newContent: string;
    }) {
      this.$store.dispatch('clubchat/updateMessage', {
        messageId: messageId,
        text: newContent,
      });
    },
    deleteMessage({ message }: { message: Message }) {
      this.$store.dispatch('clubchat/deleteMessage', message._id);
    },
  },
});
